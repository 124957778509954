import * as React from "react"
import { useState, useEffect } from "react"
import Layout from "../../components/Layout"
import Container from "../../components/Container"
import Badge from "../../components/Badge"
import { StaticImage } from "gatsby-plugin-image"
import ReactModal from 'react-modal';
import { MdClose, MdArrowForward, MdArrowBack } from "react-icons/md"

const JimTaylor = () => {
  let [image, setImage] = useState(null);

  const [modalOpen, setModalOpen] = useState(false);

  const renderStaticImage = () => {
    if (image === 1) {
      return <StaticImage placeholder="blurred" aspectRatio={4/3} src="../../../src/images/freelance/jt/1_large.jpg" alt="" className="object-cover" />;
    } else if (image === 2) {
      return <StaticImage placeholder="blurred" aspectRatio={4/3} src="../../../src/images/freelance/jt/2_large.jpg" alt="" className="object-cover" />;
    }
  }

  const renderCaption = () => {
    if (image === 1) {
      return <p className="text-sm">Jim Taylor home page design. Strikingly large quotes and basic block elements draw attention to the copy.</p>;
    } else if (image === 2) {
      return <p className="text-sm">Design for off-canvas menu visible on smaller screens.</p>;
    }
  }

  const leftArrow = () => {
    if (image === 1) {
      setImage(image = 2);
    } else {
      setImage(image = image - 1);
    }
  }

  const rightArrow = () => {
    if (image === 2) {
      setImage(image = 1);
    } else {
      setImage(image = image + 1);
    }
  }

  useEffect(() => {
    if (typeof document !== "undefined") {
      if (modalOpen === true) {
        document.body.style.overflowY = 'hidden'
      } else {
        document.body.style.overflowY = 'scroll'
      }
    }
  },[modalOpen]);

  return (
    <Layout>
      <div>
        <Container showFooter colour="0d1120" title="Jim Taylor" backLink>
          <div className="flex">
              <div className="mx-auto">
                <StaticImage placeholder="blurred" alt="" src="../../../src/images/freelance/jim-logo.jpg" className="rounded-xl shadow-lg w-32 mb-10" />
              </div>
          </div>

          <p className="mb-6 max-w-prose text-accent-text">Working with another designer, this job required a Wordpress build with a bespoke template, working from Photoshop files. With its muted colour palette and an emphasis on minamalism and typography, ensuring the copy displayed properly across all screen sizes was a must.</p>

          <div className="mb-8">
            <Badge label="Wordpress build" />

            <Badge label="Front-end" />
          </div>

          <div className="flex mb-16">
              <div className="mx-auto">
                  <a href="http://jimtaylorknowsautism.com/" rel="noreferrer" target="_blank">
                    <button className={'p-2 pl-5 pr-5 bg-transparent border-2 border-main-text text-main-text rounded-xl font-bold transition-colors duration-200 transform hover:bg-white hover:text-black focus:border-2 shadow-lg'}>Visit Jim Taylor</button>
                  </a>
              </div>
          </div>

          <div className="grid md:grid-cols-2 gap-12">
            <div onClick={() => {setModalOpen(true); setImage(1)}}>
              <StaticImage placeholder="blurred" alt="" src="../../../src/images/freelance/jt/1_thumb.jpg" className="rounded-xl shadow-lg hover:opacity-80 cursor-pointer transition-opacity duration-200 transform" />
            </div>
            
            <div onClick={() => {setModalOpen(true); setImage(2)}}>
              <StaticImage placeholder="blurred" alt="" src="../../../src/images/freelance/jt/2_thumb.jpg" className="rounded-xl shadow-lg hover:opacity-80 cursor-pointer transition-opacity duration-200 transform" />
            </div>


            <ReactModal
              isOpen={modalOpen}
              shouldCloseOnEsc={true}
              shouldReturnFocusAfterClose={true}
              shouldCloseOnOverlayClick={true}
              ariaHideApp={false}
              onRequestClose={() => {
                setModalOpen(false)
              }}
              className="grid bg-ink-500 text-white lg:rounded-xl shadow-2xl m-4 animate-fade-in-new max-w-screen-md mx-auto"
              overlayClassName="fixed text-gray-500 items-center overflow-y-scroll justify-center z-50 bg-black bg-opacity-80 left-0 right-0 top-0 bottom-0"
            >
              <div className="grid">
                <div className="ml-auto">
                  <div className="py-6 px-3 sm:px-6 sm:py-6 cursor-pointer">
                    <MdClose className="text-2xl text-white hover:text-gray-300 transition-colors duration-200 transform" onClick={() => setModalOpen(false)} />
                  </div>
                </div>
              </div>

              <div>
                {renderStaticImage()}
              </div>

              <div className="">
                <div className="flex justify-between">
                  <div className="py-6 px-3 sm:px-6 sm:py-6 cursor-pointer"><MdArrowBack className="text-2xl text-white hover:text-gray-300 transition-colors duration-200 transform" onClick={() => leftArrow()} /></div>
                  <div className="py-6 px-3 sm:px-6 sm:py-6">{renderCaption()}</div>
                  <div className="py-6 px-3 sm:px-6 sm:py-6 cursor-pointer"><MdArrowForward className="text-2xl text-white hover:text-gray-300 transition-colors duration-200 transform" onClick={() => rightArrow()} /></div>
                </div>
              </div>
            </ReactModal>
          </div>
        </Container>
      </div>
    </Layout>
  )
}

export default JimTaylor